import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { createSendSupport } from '../actions';
import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';

import LabelledInput from './LabelledInput';
import LabelledTextArea from './LabelledTextArea';

class SupportPopup extends Component {

  constructor(props) {
    super(props);
    const {
      user_first_name = '',
      user_last_name = '',
      user_email,
      login_email,
    } = props.identity || {};
    this.state = {
      support_name: user_first_name + ' ' + user_last_name,
      support_email: user_email || login_email || '',
    };
  }

  handleChange(value, field) {
    this.setState({[field]: value});
  }

  renderField(options, key) {
    const {
      label, field,
      handleChange=this.handleChange.bind(this),
      FieldClass=LabelledInput,
    } = options;
    const value = this.state[field];

    return <FieldClass key={key} className="large-12 columns"
      label={label} value={value} onChange={(value) => {
        handleChange(value, field);
      }}/>;
  }

  handleSend() {
    this.props.onSendClick(this.state);
    this.props.onClosePopup();
  }

  render() {
    const {
      identity,
      onClosePopup,
      index
    } = this.props;

    const fields = _.map([{
      label: 'Name', field: 'support_name'
    }, {
      label: 'Email Address', field: 'support_email'
    }, {
      label: 'Phone Number', field: 'support_phone'
    }, {
      label: 'Your Issue', field: 'support_issue', FieldClass: LabelledTextArea
    }, ], this.renderField.bind(this));

    return (
      <div className="reveal" style={{display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index}}
        aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Contact Support</h3>
          </div>
        </div>
        <div className="row column popup-content">
          {fields}
          <div className="column">
            A link to the current page will be included in your support request.
          </div>
          <button className="button float-right"
            type="button" onClick={this.handleSend.bind(this)}>Send</button>
        </div>
        <button className="close-button"
          aria-label="Close modal" type="button"
          onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onSendClick: (data) => {
      dispatch(createSendSupport(data));
    },
  };
};

const ConnectedSupportPopup = connect(
  mapStateToProps, mapDispatchToProps
)(SupportPopup);
export default ConnectedSupportPopup;

